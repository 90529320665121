import './index.scss'
import img from '../../assets/cost/Group.svg'
import { ReactComponent as False } from '../../assets/cost/icon.svg'
import { ReactComponent as True } from '../../assets/cost/icon (1).svg'
import Button from '../common/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import GlobalState from '../../stores/GlobalState'
import { observer } from 'mobx-react'
import classNames from 'classnames'

const Cost = observer(() => {
  const content = GlobalState.content?.cost

  if (!content) return <></>
  return (
    <section className="cost new-block-cost" id="cost">
      <div className="cost__container">
        <div className="cost__col">
          <div className="cost__title wow animated fadeInUp">
            {content.title}
          </div>
          <div className="cost__text">{content.text}</div>
          <Button
            text={content.buttonTitle}
            click={() => {
              window.open(content.list[0].link, '__blank')
            }}
          />
        </div>
        <div className="cost__block">
          <div className="cost__img wow animated zoomIn">
            <img src={img} alt="design.kitchen" />
          </div>
          {content.list.map((l: any, i: number) => (
            <CostItem content={content} l={l} key={i} i={i} />
          ))}
        </div>
      </div>
    </section>
  )
})

export default Cost

export const CostItem = ({
  content,
  l,
  i,
}: {
  content: any
  l: any
  i: number
}) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  if (!l && !content) return <></>
  return (
    <div
      className="cost__item wow animated fadeInUp"
      data-wow-delay={`${0.15 * i}s`}
    >
      <div className="cost__item-top">
        <div className="cost__item-subtitle">{l.subtitle}</div>
        <div className="cost__item-title">{l.title}</div>
        <div
          className="cost__item-old"
          dangerouslySetInnerHTML={{ __html: l.oldPrice }}
        ></div>
        <div className="cost__item-new">
          <span>{l.newPrice}</span>
          {content.allCourse}
        </div>
        <div className="cost__item-subtext">{content.subtext}</div>
        <Button
          text={content.buttonTitle}
          click={() => {
            if (!l.link) {
              navigate('/cost')
              localStorage.setItem('card', JSON.stringify({ ...l, content }))
            } else window.open(l.link, '__blank')
          }}
        />
        {l.subtext?.length && !pathname.includes('cost') ? (
          <div className="cost__item-unic">{l.subtext}</div>
        ) : (
          <></>
        )}
      </div>
      <div className="cost__item-scroll">
        <div className="cost__item-list">
          {l.possibility.map((p: any, id: number) => (
            <div
              className={classNames('cost__item-param', !p && 'no-chosen')}
              key={id}
            >
              {!p ? <False /> : <True />}
              {content.possibility[id]}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
