import { useEffect, useRef, useState } from 'react'
import IMask from 'imask'

const PhoneInput = ({
  placeholder,
  getValue,
}: {
  placeholder: string
  getValue: (value: string) => void
}) => {
  const [active, setActive] = useState(false)
  const mask = useRef<any>(null)
  const ref = useRef<any>(null)
  const [phone, setPhone] = useState('')
  useEffect(() => {
    if (!ref.current) return
    const maskOptions = {
      mask: '+38 ({0}00) 000-00-00',
      lazy: !active,
    }
    mask.current = IMask(ref.current, maskOptions)
      .on('accept', () => {
        setPhone(mask.current.value)

        mask.current.updateValue()
      })
      .on('complete', () => {
        // setPhone(mask.current.value)
        getValue(mask.current.value)
        mask.current.updateValue()
      })

    return () => {
      mask.current.destroy()
    }
  }, [active, phone])

  useEffect(() => {
    getValue(phone)
  }, [phone])
  return (
    <input
      onFocus={() => setActive(true)}
      onBlur={() => {
        setActive(false)
      }}
      autoComplete="off"
      ref={ref}
      className="input"
      value={phone}
      placeholder={placeholder}
      onChange={(e) => {
        setPhone(e.target.value)
        mask.current.updateValue()
      }}
    />
  )
}

export default PhoneInput
