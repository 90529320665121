import './index.scss'
import { observer } from 'mobx-react'
import GlobalState from '../../stores/GlobalState'
import Lottie from 'react-lottie'
import json from '../../lottie/eyes.json'
import { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import { ReactComponent as Arrow } from '../../assets/chevron.svg'
import classNames from 'classnames'

const Teachers = observer(() => {
  const content = GlobalState.content?.teachers
  const [width, setWidth] = useState(window.innerWidth)
  const [currentSlide, setSlide] = useState(0)
  const ref = useRef<any>(null)
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: json,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))
  }, [])

  const isLast = (currentSlide: number) => {
    console.log(currentSlide)
    const perSlide = width > 1150 ? 3 : width > 768 && width <= 1150 ? 2 : 1
    //currentSlide == content.list.length - 1
    return currentSlide + perSlide == content.list.length
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 700,
    variableWidth: false,
    slidesToShow: width > 1150 ? 3 : width > 768 && width <= 1150 ? 2 : 1,
    slidesToScroll: 1,
    nextArrow: (
      <div className="reviews__btn back wow animated fadeInUp">
        <Arrow />
      </div>
    ),
    prevArrow: (
      <div className="reviews__btn next wow animated fadeInUp">
        <Arrow />
      </div>
    ),
  }

  console.log(currentSlide)
  if (!content) return <></>
  return (
    <section className="teachers" id="teachers">
      <div className="teachers__container">
        <div className="teachers__top">
          {GlobalState.width <= 900 && (
            <div className="teachers__img wow animated fadeIn">
              {' '}
              <Lottie options={defaultOptions} />
            </div>
          )}
          <div className="teachers__title wow animated fadeInUp">
            {content.title}
          </div>
          <div className="teachers__text  wow animated fadeInUp">
            {content.text}
            <span>
              {content.awwards
                .trim()
                .split(',')
                .map((a: any, i: number) => (
                  <a href={content.hrefs[i]} target="__blank" key={i}>
                    {a}
                    {i != content.awwards.trim().split(',').length - 1
                      ? ', '
                      : ''}
                  </a>
                ))}
            </span>
          </div>
          {GlobalState.width > 900 && (
            <div className="teachers__img  wow animated fadeIn">
              <Lottie options={defaultOptions} />
            </div>
          )}
        </div>
        <div className="teachers__list">
          <Slider
            {...settings}
            ref={ref}
            afterChange={(index) => setSlide(index)}
          >
            {content.list.map((li: any, i: number) => (
              <div
                className="teachers__item  wow animated fadeInUp"
                key={i}
                data-wow-duration="1s"
                data-wow-delay={`${0.15 + 0.15 * i}s`}
              >
                <div className="teachers__item-img">
                  <img src={li.img} alt="design.kitchen" />
                </div>
                <div className="teachers__item-info">
                  <div className="teachers__item-title">{li.title}</div>
                  <div
                    className="teachers__item-text"
                    dangerouslySetInnerHTML={{ __html: li.text }}
                  ></div>
                  {li.socials.length ? (
                    <div className="teachers__item-socials">
                      {li.socials.map((si: any, i: number) => (
                        <a href={si.link} key={i} target="__blank">
                          <img src={si.icon} alt-="design.kitchen" />
                        </a>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="reviews__btns">
          <div
            className={classNames(
              'reviews__btn back wow animated fadeInUp',
              !currentSlide && 'disabled-bt',
            )}
            onClick={() => ref.current.slickPrev()}
          >
            <Arrow />
          </div>
          <div
            className={classNames(
              'reviews__btn next wow animated fadeInUp',
              isLast(currentSlide) && 'disabled-bt',
            )}
            onClick={() => ref.current.slickNext()}
          >
            <Arrow />
          </div>
        </div>
      </div>
    </section>
  )
})

export default Teachers
