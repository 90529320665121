import { observable, runInAction } from 'mobx'
const GlobalState: any = observable({
  isPopShow: false,
  width: window.innerWidth,
  review: null,
  cost: null,
  content: null,
})

export const changePopState = () => {
  runInAction(() => {
    GlobalState.isPopShow = !GlobalState.isPopShow
  })
}

export const setContent = () => {
  fetch('content.json')
    .then((res) => res.json())
    .then((result) => {
      runInAction(() => {
        GlobalState.content = result
      })
    })
}

export const setReview = (review: any) => {
  runInAction(() => {
    GlobalState.review = review
    GlobalState.isPopShow = true
  })
}

export const subscribe = async (dt: any) => {
  try {
    const fd = new FormData()
    fd.append('phone', dt)
    fetch('https://designkitchen.com.ua/mail.php', {
      method: 'POST',
      body: fd,
    })
  } catch (e) {
    console.log(e)
  }
}

export const send = async (dt: any) => {
  try {
    const fd = new FormData()
    fd.append('name', dt.name)
    fd.append('email', dt.email)
    fd.append('phone', dt.phone)
    fetch('https://designkitchen.com.ua/mail3.php', {
      method: 'POST',
      body: fd,
    })
  } catch (e) {
    console.log(e)
  }
}

export const subscribeFormat = async (dt: any) => {
  try {
    const fd = new FormData()
    fd.append('phone', dt.phone)
    fd.append('format', dt.format)
    fetch('https://designkitchen.com.ua/mail2.php', {
      method: 'POST',
      body: fd,
    })
  } catch (e) {
    console.log(e)
  }
}

export default GlobalState






// ,
//       {
//         "subtitle": "Для тих, хто любить ставити питання",
//         "subtext": null,
//         "title": "Груповий",
//         "oldPrice": "<span>₴18 000</span> звичайна ціна",
//         "newPrice": "₴11 000",
//         "link": null,
//         "possibility": [
//           true,
//           true,
//           true,
//           true,
//           true,
//           true,
//           true,
//           true,
//           true,
//           false,
//           false,
//           false
//         ]
//       },
//       {
//         "subtitle": "Максимум практики",
//         "title": "Індивідуальний",
//         "subtext": "Вільно 2 із 3 місць",
//         "oldPrice": "<span>₴25 000</span> звичайна ціна",
//         "newPrice": "₴16 000",
//         "link": null,
//         "possibility": [
//           true,
//           true,
//           true,
//           true,
//           true,
//           true,
//           true,
//           true,
//           true,
//           true,
//           true,
//           true
//         ]
//       }