import Home from './pages/Home'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Thanks from './pages/Thanks'
import Contact from './pages/Contact'
import { useEffect } from 'react'
import { runInAction } from 'mobx'
import GlobalState from './stores/GlobalState'
import { observer } from 'mobx-react'
import ThanksMarathone from './pages/Thanks-marathone'

export type RouteType = {
  path: string
  component: any
}

const routes: Array<RouteType> = [
  { path: '/', component: <Home /> },
  { path: '/thanks', component: <Thanks /> },
  { path: '/cost', component: <Contact /> },
  {
    path: '/thanks-marathon',
    component: <ThanksMarathone />,
  },
]

const App = observer(() => {
  useEffect(() => {
    window.addEventListener('resize', () => {
      runInAction(() => {
        GlobalState.width = window.innerWidth
      })
    })
  }, [])
  
  return (
    <>
      <Router>
        <Routes>
          {routes.map((route, idx) => (
            <Route key={idx} path={route.path} element={route.component} />
          ))}
        </Routes>
      </Router>
    </>
  )
})

export default App
