import Button from '../common/Button'
import './index.scss'
import Lottie from 'react-lottie'
import json from '../../lottie/cat.json'
import { useEffect } from 'react'
import { observer } from 'mobx-react'
import GlobalState from '../../stores/GlobalState'

const Ask = observer(() => {
  const content = GlobalState.content?.ask
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: json,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {}, [content])

  if (!content) return <></>
  return (
    <section className="ask">
      <div className="ask__container">
        <div className="ask__col">
          <div className="ask__title wow animated fadeInUp">
            {content.title}
          </div>
          <div className="ask__text wow animated fadeInUp">{content.text}</div>
          <Button
            text={content.buttonTitle}
            click={() => {
              window.open('https://t.me/dk_school', '__blank')
            }}
          />
        </div>
        <div className="ask__img wow animated fadeInUp">
          <Lottie options={defaultOptions} />
        </div>
      </div>
    </section>
  )
})

export default Ask
