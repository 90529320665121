import './index.scss'
import { ReactComponent as Cat } from '../../assets/pop/cat.svg'
import { observer } from 'mobx-react'
import GlobalState from '../../stores/GlobalState'
const CourseStructure = observer(() => {
  const content = GlobalState.content?.course_structure

  return (
    <section className="course-struct">
      <div className="course-struct__container">
        <div className="course-struct__top">
          <div
            className="course-struct__subtitle wow animated fadeInUp"
            data-wow-delay="0.35s"
          >
            {content?.subtitle}
          </div>
          <div
            className="course-struct__title wow animated fadeInUp"
            data-wow-duration="1.25s"
            data-wow-delay="0.35s"
          >
            {content?.title}
          </div>
        </div>
        <div className="course-struct__list">
          <div
            className="course-struct__img  wow zoomIn animated"
            data-wow-duration="0.75s"
          >
            <Cat />
          </div>

          {content?.list?.map((c: any, i: number) => (
            <div
              className="course-struct__item wow fadeInUp animated"
              key={i}
              data-wow-duration="0.75s"
              data-wow-delay={`${0.15 * i}s`}
            >
              <div className="course-struct__item-title">{c.title}</div>
              <div className="course-struct__item-text">{c.text}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
})

export default CourseStructure
