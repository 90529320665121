import { useEffect, useState } from 'react'
import Button from '../common/Button'
import PhoneInput from '../common/PhoneInput'
import './index.scss'
import { CostItem } from '../Cost'
import GlobalState, { send, subscribe, subscribeFormat } from '../../stores/GlobalState'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
const WOW = require('wow.js')

const ContactContent = observer(() => {
  const [value, setValue] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()
  useEffect(() => {
    new WOW().init()
  }, [])

  const submit = () => {
    const phone = value
      .replaceAll('+', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('-', '')
      .replaceAll(' ', '')
    if (!value.length || value.includes('_') || +phone[2] != 0) {
      setError(content.error)
      ;(document.querySelector(
        '.contacts__form',
      ) as HTMLElement).style.setProperty(
        '--input-error',
        JSON.stringify(content.error),
      )
      return
    } else setError('')
    subscribeFormat({ phone, format: content.item.title }).then(() => {
      navigate('/thanks')
    })
  }

  if (!GlobalState.content?.contactP) return <></>
  const content = { ...GlobalState.content?.contactP, item: GlobalState.cost }

  if (!content) return <></>
  return (
    <section className="contacts">
      <div className="contacts__container">
        <div className="contacts__col">
          <div className="contacts__title wow animated fadeInUp">
            {content.title} “{(GlobalState.cost as any)?.title}”
          </div>
          <div className="contacts__text wow animated fadeInUp">
            {content.text}
          </div>
          <div
            className={classNames(
              ' wow animated fadeInUp contacts__form',
              error.length && 'error',
            )}
          >
            <PhoneInput
              placeholder={content.placeholder}
              getValue={(value) => setValue(value)}
            />
            <Button text={content.buttonTitle} click={submit} />
          </div>
          {GlobalState.cost && GlobalState.cost?.title != 'Груповий' && (
            <div className="contacts__imgs">
              {content.imgs.map((im: any, i: number) => (
                <div
                  className="contacts__img wow animated zoomIn"
                  key={i}
                  data-wow-delay={`${0.15 * i}s`}
                >
                  <img src={im} alt="design.kitchen" />
                </div>
              ))}
            </div>
          )}
        </div>
        {GlobalState.cost && (
          <CostItem l={content.item} content={content} i={0} />
        )}
      </div>
      <div className="thanks__bottom">
        {content.links.map((l: any, i: number) => (
          <a
            href={l.link}
            key={i}
            target="__blank"
            className="thanks__bottom-link  wow animated fadeInUp"
          >
            {l.title}
          </a>
        ))}
      </div>
    </section>
  )
})

export default ContactContent
